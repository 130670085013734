import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchSelectedTab } from "../../state/analytics/analyticsActions"
import { TabsSection, Tab, SelectedTab } from "../../style/analytics"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby-link"

export const AnalyticsTab = ({ activeTab }) => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(activeTab)

    const handleTab = (selection) => {
        console.log("SELECTION", selection)
        setSelected(selection);
        navigate("/analytics");
    }

    useEffect(() => {
        dispatch(fetchSelectedTab(selected))
    }, [selected]);

    return (
        <>
            {/* <h1>Listener  and Client Analytics</h1> */}
            <br />
            <br />
            <TabsSection>
                <Tab style={selected === "LISTENERS" ? SelectedTab : {}} onClick={() => handleTab("LISTENERS")}>
                    Listeners
                </Tab>
                <Tab style={selected === "CLIENTS" ? SelectedTab : {}} onClick={() => handleTab("CLIENTS")}>
                    Clients
                </Tab>
                <Tab style={selected === "DOCTORS" ? SelectedTab : {}} onClick={() => handleTab("DOCTORS")}>
                    Doctors
                </Tab>
            </TabsSection>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        selectedTab: state.analytics.selectedTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSelectedTab: (selectedTab) => dispatch(fetchSelectedTab(selectedTab)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTab);