import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { Layout } from "../../components/Layout"
import { BallBeat } from "react-pure-loaders"
import { fetchDoctorsDetailList } from "../../state/analytics/analyticsActions"
import { AnalyticsSection } from "../../style/analytics"
import { DoctorAnalyticsDetailsTable } from "../../components/DoctorAnalyticsDetailsTable"
import { AnalyticsTab } from "./analyticsTab";

function doctorAnalytics({
    analyticsData,
    analyticsDetailList,
    fetchDoctorsDetailList,
    location = {},
    selectedTab
}) {

    if (location.state === null || undefined) {
        navigate("/")
    }

    let doctorId = location.state ? location.state.data.doctor_id : " "

    useEffect(() => {
        fetchDoctorsDetailList(doctorId)
    }, [fetchDoctorsDetailList])

    useEffect(() => {
        if (selectedTab !== "DOCTORS") {
            navigate("/analytics")
        }
    }, [selectedTab])

    return (
        <div>
            <Layout>
                <AnalyticsSection>
                    <AnalyticsTab activeTab="DOCTORS" />
                    <br />
                    <br />

                    <h4 >
                        Appointment details of Doctor ID: -{" "}
                        <span style={{ color: "#4299E1" }}> {location.state ? location.state.data.doctor_id : " "}</span>
                        &nbsp;  Doctor Name -{" "}
                        <span style={{ color: "#4299E1" }}>{location.state ? location.state.data.lname : " "} </span>

                    </h4> 
                    {/* <h4>
                        Doctor Name -{" "}
                        <span >
                            {location.state ? location.state.data.lname : " "}
                        </span>
                    </h4> */}
                    {analyticsData.loading ?
                        (
                            <>
                                <br />
                                <br />
                                <h4>
                                    Loading Doctor Analytics Details{" "}
                                    <span>
                                        <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                    </span>
                                </h4>
                            </>
                        ) :
                        analyticsData.error ?
                            (
                                <>
                                    <br />
                                    <h2> Doctor  Analytics</h2>
                                    <br />
                                    <h4> ERROR ⚠️ FETCHING DOCTOR ANALYTICS DETAILS</h4>
                                    <br />
                                    <p style={{ color: "grey" }}>{analyticsData.error}</p>
                                    <br />
                                    <br />
                                </>
                            ) : analyticsDetailList.length === 0 ?
                                (
                                    <>
                                        <br />
                                        <br />
                                        <h4> No appointment history available for the doctor</h4>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                ) :
                                <>
                                    <br />
                                    <DoctorAnalyticsDetailsTable doctorsDetailList={analyticsDetailList} />
                                </>
                    }
                </AnalyticsSection>
            </Layout>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        analyticsData: state.analytics,
        analyticsDetailList: state.analytics.doctorDetails,
        selectedTab: state.analytics.selectedTab,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDoctorsDetailList: (doctorId) => dispatch(fetchDoctorsDetailList(doctorId)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(doctorAnalytics)
