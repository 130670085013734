import axios from "axios"
import {
    FETCH_LISTENER_ANALYTICS,
    FETCH_LISTENER_ANALYTICS_SUCC,
    FETCH_LISTENER_ANALYTICS_FAIL,
    FETCH_LISTENER_DETAILS,
    FETCH_LISTENER_DETAILS_SUCC,
    FETCH_LISTENER_DETAILS_FAIL,
    FETCH_CLIENT_ANALYTICS,
    FETCH_CLIENT_ANALYTICS_SUCC,
    FETCH_CLIENT_ANALYTICS_FAIL,
    FETCH_CLIENT_DETAILS,
    FETCH_CLIENT_DETAILS_SUCC,
    FETCH_CLIENT_DETAILS_FAIL,
    FETCH_DOCTOR_ANALYTICS,
    FETCH_DOCTOR_ANALYTICS_SUCC,
    FETCH_DOCTOR_ANALYTICS_FAIL,
    FETCH_DOCTOR_DETAILS,
    FETCH_DOCTOR_DETAILS_SUCC,
    FETCH_DOCTOR_DETAILS_FAIL,
    FETCH_SELECTED_TAB,
} from "./analyticsTypes"

import {
    fetchListnerAnalyticsListenerRequestsStats,
    fetchListnerAnalyticsListenerRequestsStatsDetail,
    fetchPatientAnalyticsListenerRequestsStatsDetail,
    fetchPatientAnalyticsListenerRequestsStats,
    fetchListnerAppointmentDetail,
    fetchListnerAppointmentStats,
    doctoriteId
} from "../../urls"

export const fetchListenerAnalytics = () => {
    return {
        type: FETCH_LISTENER_ANALYTICS,
    }
}

export const fetchListenerAnalyticsSuccess = (listeners) => {
    return {
        type: FETCH_LISTENER_ANALYTICS_SUCC,
        payload: listeners,
    }
}

export const fetchListenerAnalyticsFailure = (error) => {
    return {
        type: FETCH_LISTENER_ANALYTICS_FAIL,
        payload: error,
    }
}

export const fetchListenerDetails = () => {
    return {
        type: FETCH_LISTENER_DETAILS,
    }
}

export const fetchListenerDetailsSuccess = (listenerDetails) => {
    return {
        type: FETCH_LISTENER_DETAILS_SUCC,
        payload: listenerDetails,
    }
}

export const fetchListenerDetailsFailure = (error) => {
    return {
        type: FETCH_LISTENER_DETAILS_FAIL,
        payload: error,
    }
}

export const fetchClientAnalytics = () => {
    return {
        type: FETCH_CLIENT_ANALYTICS,
    }
}

export const fetchClientAnalyticsSuccess = (clients) => {
    return {
        type: FETCH_CLIENT_ANALYTICS_SUCC,
        payload: clients,
    }
}

export const fetchClientAnalyticsFailure = (error) => {
    return {
        type: FETCH_CLIENT_ANALYTICS_FAIL,
        payload: error,
    }
}

export const fetchClientDetails = () => {
    return {
        type: FETCH_CLIENT_DETAILS,
    }
}

export const fetchClientDetailsSuccess = (clientDetails) => {
    return {
        type: FETCH_CLIENT_DETAILS_SUCC,
        payload: clientDetails,
    }
}

export const fetchClientDetailsFailure = (error) => {
    return {
        type: FETCH_CLIENT_DETAILS_FAIL,
        payload: error,
    }
}

export const fetchDoctorAnalytics = () => {
    return {
        type: FETCH_DOCTOR_ANALYTICS,
    }
}

export const fetchDoctorAnalyticsSuccess = (doctors) => {
    return {
        type: FETCH_DOCTOR_ANALYTICS_SUCC,
        payload: doctors,
    }
}

export const fetchDoctorAnalyticsFailure = (error) => {
    return {
        type: FETCH_DOCTOR_ANALYTICS_FAIL,
        payload: error,
    }
}

export const fetchDoctorDetails = () => {
    return {
        type: FETCH_DOCTOR_DETAILS,
    }
}

export const fetchDoctorDetailsSuccess = (doctorDetails) => {
    return {
        type: FETCH_DOCTOR_DETAILS_SUCC,
        payload: doctorDetails,
    }
}

export const fetchDoctorDetailsFailure = (error) => {
    return {
        type: FETCH_DOCTOR_DETAILS_FAIL,
        payload: error,
    }
}

export const updateTab = (selectedTab) => {
    return {
        type: FETCH_SELECTED_TAB,
        payload: selectedTab,
    }
}

export const fetchListenersList = () => {
    return (dispatch) => {
        dispatch(fetchListenerAnalytics())
        let url = fetchListnerAnalyticsListenerRequestsStats;
        axios.get(url)
            .then((response) => {
                dispatch(fetchListenerAnalyticsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchListenerAnalyticsFailure(error.message))
            })
    }
}

export const fetchListenersDetailList = (listenerId) => {
    return (dispatch) => {
        dispatch(fetchListenerDetails())
        let url = fetchListnerAnalyticsListenerRequestsStatsDetail;
        let inp_listenerId = listenerId
        if (inp_listenerId != null || inp_listenerId != " ") {
            url = url + `?listner=${inp_listenerId}`
        }
        axios.get(url)
            .then((response) => {
                dispatch(fetchListenerDetailsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchListenerDetailsFailure(error.message))
            })
    }
}

export const fetchClientsList = () => {
    return (dispatch) => {
        dispatch(fetchClientAnalytics())
        let url = fetchPatientAnalyticsListenerRequestsStats;
        axios.get(url)
            .then((response) => {
                console.log("Response", response)
                dispatch(fetchClientAnalyticsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchClientAnalyticsFailure(error.message))
            })
    }
}

export const fetchClientsDetailList = (clientId) => {
    return (dispatch) => {
        dispatch(fetchClientDetails())
        let url = fetchPatientAnalyticsListenerRequestsStatsDetail;
        let inp_clientId = clientId
        if (inp_clientId != null || inp_clientId != " ") {
            url = url + `?patientId=${inp_clientId}`
        }
        axios.get(url)
            .then((response) => {
                dispatch(fetchClientDetailsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchClientDetailsFailure(error.message))
            })
    }
}

export const fetchDoctorsList = () => {
    return (dispatch) => {
        dispatch(fetchDoctorAnalytics())
        let url = fetchListnerAppointmentStats;
        axios.get(url)
            .then((response) => {
                console.log("Response", response)
                dispatch(fetchDoctorAnalyticsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchDoctorAnalyticsFailure(error.message))
            })
    }
}

export const fetchDoctorsDetailList = (doctorId) => {
    return (dispatch) => {
        dispatch(fetchDoctorDetails())
        let url = fetchListnerAppointmentDetail;
        let inp_doctorId = doctorId
        if (inp_doctorId != null || inp_doctorId != " ") {
            url = url + `?listner=${inp_doctorId}`
        }
        axios.get(url)
            .then((response) => {
                dispatch(fetchDoctorDetailsSuccess(response?.data?.responseData))
            })
            .catch((error) => {
                dispatch(fetchDoctorDetailsFailure(error.message))
            })
    }
}

export const fetchSelectedTab = (selectedTab) => {
    return (dispatch) => {
        dispatch(updateTab(selectedTab))
    }
}
