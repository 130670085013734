import styled from "styled-components"
import { Section } from "./globalStyles"

export const AnalyticsSection = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
margin: 2rem;
padding: 0;
h1 {
  font-size: 1.7rem;
}
h2 {
  font-size: 1.5rem;
}
`

export const Tabs = styled.div`
  ${tw`flex flex-row p-1`};
  background-color: #ededed;
  width: fit-content;
  border-radius: 5px;
`
export const TabsSection = styled.div`
  ${tw`flex flex-row p-1`};
  background-color: #ededed;
  width: fit-content;
  border-radius: 5px;
`

export const Tab = styled.div`
  ${tw`px-2 py-1 text-base text-black text-center cursor-pointer`};
  width: 100px;
`

export const SelectedTab = {
  borderRadius: "5px",
  backgroundColor: "#4299E1",
  color: "white"
}