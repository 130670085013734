import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const DoctorAnalyticsDetailsTable = ({ doctorsDetailList }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Client ID",
                accessor: "pid",
            },
            {
                Header: "Client Name",
                accessor: "pname",
            },
            {
                Header: "Reason",
                accessor: "reason",
            },
            {
                Header: "Order Seq Id",
                accessor: "order_seq_id",
            },
            {
                Header: "Appointment Date",
                accessor: "appointment_date",
            },
            {
                Header: "Status",
                accessor: "status",
            },
        ],
        []
    )

    var tableRender = (
        <Table
            columns={columns}
            data={doctorsDetailList}
        />
    )

    return <TableStyles>{doctorsDetailList ? tableRender : " "} </TableStyles>
}